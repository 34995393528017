import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from '../../components/AppBar/AppBar';

import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";

import Footer from "../../components/Footer/Footer";

import { IoMail } from "react-icons/io5";
import { IoLogoInstagram } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";

import { sendFeedback } from "../../services/sendFeedback";

import styles from './Contact.module.css';

const Contact = () => {

    const [userEmail, setUserEmail] = useState('');
    const [userEmailDisabled, setUserEmailDisabled] = useState(false);
    const [subject, setSubject] = useState('');
    const [subjectDisabled, setSubjectDisabled] = useState(false);
    const [message, setMessage] = useState('');
    const [messageDisabled, setMessageDisabled] = useState(false);

    const [sendingFeedback, setSendingFeedback] = useState(false);
    const [feedBackSent, setFeedbackSent] = useState(false);

    const [error, setError] = useState(false);

    const handleSendFeedback = async (e) => {
        e.preventDefault();
        setSendingFeedback(true);

        let response;

        try {
            response = await sendFeedback({
                userEmail,
                messageHeader: subject,
                message
            });
        }
        catch (error) {
            setError(true);
            return;
        }

        setSendingFeedback(false);

        if (response.ok) {
            setFeedbackSent(true);
            setError(false);

            setUserEmailDisabled(true);
            setSubjectDisabled(true);
            setMessageDisabled(true);
        } else {
            setError(true);
        }

    }

    return (
        <>
            <Container fluid className="mainContainer">
                <Container fluid className={styles.appbarContainer}>
                    <AppBar />
                </Container>

                <>
                    <Container
                        fluid
                        className={styles.header}
                    >
                        <h1 className={styles.headerTitle}>
                            İletişim
                        </h1>
                    </Container>

                    <div className={styles.contactContainer}>
                        <div className={styles.contactRow}>
                            <Col className={styles.infoColumn} xs={12} lg={6}>
                                <div className={styles.contactInfoNote}>
                                    Bize iletişim formumuzu kullanarak, bilinclituketimcom@gmail.com adresine e-posta göndererek ya da sosyal medya hesaplarımız aracılığıyla ulaşabilirsiniz.
                                </div>

                                <Row className={`mb-4 d-flex align-items-center ${styles.infoRow}`}>
                                    <div className="m-0 p-0 d-flex align-items-center" style={{ fontSize: "25px" }}>
                                        <TbWorld />
                                        <span style={{ marginLeft: 20, fontWeight:"bold", fontSize:"12pt"}}> www.bilinclituketim.com </span>
                                    </div>
                                </Row>


                                <Row className={`my-4 d-flex align-items-center ${styles.infoRow}`}>
                                    <div className="m-0 p-0 d-flex align-items-center" style={{ fontSize: "25px" }}>
                                        <IoMail />
                                        <a
                                            href="mailto:bilinclituketimcom@gmail.com"
                                            style={{ marginLeft: 20, fontWeight:"bold", fontSize:"12pt",
                                            cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
                                        > 
                                            bilinclituketimcom@gmail.com
                                        </a> 
                                    </div>
                                </Row>

                                <Row className={`my-4 d-flex align-items-center ${styles.infoRow}`}>
                                    <div className="m-0 p-0 d-flex align-items-center" style={{ fontSize: "25px" }}>
                                        <IoLogoInstagram />
                                        <a
                                            target="_blank"
                                            href="https://www.instagram.com/bilinclituketimcom/"
                                            style={{ marginLeft: 20, fontWeight:"bold", fontSize:"12pt",
                                            cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
                                        > 
                                            bilinclituketimcom
                                        </a> 
                                    </div>
                                </Row>

                                <Row className={`mt-4 d-flex align-items-center ${styles.infoRow}`}>
                                <div className="m-0 p-0 d-flex align-items-center" style={{ fontSize: "25px" }}>
                                        <FaXTwitter />
                                        <a
                                            target="_blank"
                                            href="https://twitter.com/bilincli_tuket"
                                            style={{ marginLeft: 20, fontWeight:"bold", fontSize:"12pt",
                                            cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
                                        > 
                                            bilincli_tuket
                                        </a> 
                                    </div>
                                </Row>


                            </Col>

                            <Col className={styles.formColumn} xs={12} lg={6}>
                                <Form onSubmit={(e) => handleSendFeedback(e)}>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>E-posta Adresiniz</Form.Label>
                                        <Form.Control
                                            type="email"
                                            required
                                            value={userEmail}
                                            disabled={userEmailDisabled}
                                            onChange={(e) => setUserEmail(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="subject">
                                        <Form.Label>Konu</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength={50}
                                            minLength={3}
                                            required
                                            value={subject}
                                            disabled={subjectDisabled}
                                            onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Label>Mesajınız</Form.Label>
                                    <Form.Group className="mb-3" controlId="message">
                                        <Form.Control
                                            as="textarea"
                                            rows={2}
                                            maxLength={500}
                                            value={message}
                                            disabled={messageDisabled}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" required className="me-2"/>
                                        <Form.Label>Bilinçli Tüketim Hareketi'nin <a href="gizlilik-politikasi">Kişisel Verileri Koruma ve İşleme Politikası</a>'nı okudum, onaylıyorum.</Form.Label>
                                    </Form.Group>
                                    <Container className="d-flex justify-content-center">
                                        <Button
                                            size="lg"
                                            type="submit"
                                            style={{ backgroundColor: '#a00000', border: 0, width: '200px' }}
                                            disabled={feedBackSent || sendingFeedback}
                                        >
                                            {sendingFeedback ? <Spinner size="sm" /> :
                                                "Gönder"
                                            }
                                        </Button>
                                    </Container>

                                    {feedBackSent &&
                                        <div className="d-flex justify-content-center mt-4">

                                            <span className="text-success fw-bold">Mesajınız başarıyla gönderildi.</span>

                                        </div>
                                    }
                                    {error &&
                                        <div className="d-flex justify-content-center mt-4">

                                            <span className="text-danger fw-bold">Mesaj gönderilemedi. Lütfen daha sonra tekrar deneyin.</span>

                                        </div>
                                    }

                                </Form>
                            </Col>
                        </div>
                    </div>
                </>
            </Container>
            <Footer />
        </>
    );
}

export default Contact;
